<template>
  <div 
    id="confirmation"
  >
    <Menu/>
    <b-container>
      <p>Merci pour ta participation. <br><br><br>Nous avons hâtes de te voir,<br><br> à très vite !</p><br>
      <p>Laëtitia & Pierre</p>
    </b-container>
  </div>
</template>

<script>
import Menu from './../components/Menu.vue'
export default {
  components: {
    Menu
  },
}
</script>